<template>
  <div v-infinite-scroll="loadNextPage" infinite-scroll-disabled="busy" infinite-scroll-distance="8">
    <div class="columns is-multiline is-centered mt-4" v-lazy-container="{ selector: 'img' }">
      <div class="column is-3" v-for="post in posts">
        <div class="service-block wow fadeInUp card hvr-grow">
          <figure class="image">
            <img :data-src="post.image" class="image-cover height-250" :alt="post.title" />
          </figure>
          <div class="px-4 py-4">
            <h4 class="mb-3 has-text-weight-bold has-text-primary">{{post.title}}</h4>
            <div class="has-text-black-bis mb-3">
              <small v-html="post.content">
              </small>
            </div>
            <a :href="post.link" class="has-text-warning has-text-weight-bold">{{ t('more') }}
              <i v-if="lang == 'ar' " class="fas fa-arrow-left vertical-sub"></i>
              <i v-else class="fas fa-arrow-right vertical-sub"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading state -->
    <div class="has-text-centered full-width mt30" v-show="loadMore">
      <div class="iloader position-relative is-inline-block w-100">
        <img src="/front/img/illustrations/loader.svg" alt="" class="height-100" />
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import infiniteScroll from 'vue-infinite-scroll';
  export default {
    name: "Blog",
    directives: {
      infiniteScroll
    },
    props: {
      lang:{
        type: String,
        required: true
      },
      url: String,
    },
    computed: {
      busy() {
        return this.fetching
      }
    },
    data(){
      return {
        currentPage: 0,
        fetching: true,
        loadMore : true,
        nextUrl : String,
        posts: [],
      };
    },
    created() {
      this.nextUrl = this.url;
      this.posts = [];
      this.loadNextPage();
    },
    mounted() {
      this.$translate.setLang(this.lang);
    },
    methods: {
      loadNextPage() {
        this.currentPage++;
        this.fetching = true;
        if(this.nextUrl != null){
          axios.get(this.nextUrl)
          .then(({ data }) => {
            this.posts.push(...data.data);
            if(data.links.next != null) {
              this.nextUrl = data.links.next;
            } else {
              this.nextUrl = data.links.next;
            }
            this.fetching = false;
          });
        }
        else{
          this.loadMore = false;
        }
      },
    },
    watch: {

    },
  }
</script>

<style scoped>

</style>
