<template>
  <div v-infinite-scroll="loadNextPage" infinite-scroll-disabled="busy" infinite-scroll-distance="8">
    <div class="columns is-multiline is-centered mt-4" v-lazy-container="{ selector: 'img' }">
      <div class="column is-3" v-for="doctor in doctors">
        <div class="item card w-100 hvr-grow has-text-centered px-3 py-3">
          <figure class="image">
            <img :alt="doctor.name" class="height-220 image-contain" :data-src="doctor.image">
          </figure>
          <div class="is-overlay">
            <h4 class="is-size-6 has-text-white has-text-weight-bold mb-3">{{  doctor.name }}</h4>
            <a :href="doctor.link" class="has-text-white">
             <i class="fas fa-eye is-size-3"></i>
           </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading state -->
    <div class="has-text-centered full-width mt30" v-show="loadMore">
      <div class="iloader position-relative is-inline-block w-100">
        <img src="/front/img/illustrations/loader.svg" alt="" class="height-100" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import infiniteScroll from 'vue-infinite-scroll';
export default {
  name: "Doctors",
  directives: {
    infiniteScroll
  },
  props: {
    lang:{
      type: String,
      required: true
    },
    url: String,
  },
  computed: {
    busy() {
      return this.fetching
    }
  },
  data(){
    return {
      currentPage: 0,
      fetching: true,
      loadMore : true,
      nextUrl : String,
      doctors: [],
    };
  },
  created() {
    this.nextUrl = this.url;
    this.doctors = [];
    this.loadNextPage();
  },
  mounted() {
    this.$translate.setLang(this.lang);
  },
  methods: {
    loadNextPage() {
      this.currentPage++;
      this.fetching = true;
      if(this.nextUrl != null){
        axios.get(this.nextUrl)
        .then(({ data }) => {
          this.doctors.push(...data.data);
          if(data.links.next != null) {
            this.nextUrl = data.links.next;
          } else {
            this.nextUrl = data.links.next;
          }
          this.fetching = false;
        });
      }
      else{
        this.loadMore = false;
      }
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
