'use strict';

document.addEventListener('DOMContentLoaded', function () {

  if(window.innerWidth <= 1023){
    // Dropdowns
    var $dropdowns = getAll('.dropdown.is-clickable-mobile');
    if ($dropdowns.length > 0) {
      $dropdowns.forEach(function ($el) {
        $el.classList.remove("is-hoverable");
        $el.addEventListener('click', function (event) {
          event.stopPropagation();
          $el.classList.toggle('is-active');
        });
      });

      document.addEventListener('click', function (event) {
        closeDropdowns();
      },{passive: true});
    }

    function closeDropdowns() {
      $dropdowns.forEach(function ($el) {
        $el.classList.remove('is-active');
      });
    }

    // Close dropdowns if ESC pressed
    document.addEventListener('keydown', function (event) {
      var e = event || window.event;
      if (e.keyCode === 27) {
        closeDropdowns();
      }
    }, {passive: true});

    // Functions

    function getAll(selector) {
      return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
    }
  }
  $('a[href*=\\#]:not([href=\\#])').on('click', function() {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.substr(1) +']');
    if (target.length) {
      $('html,body').animate({
          scrollTop: target.offset().top
      }, 1500);
      return false;
    }
  });

  setTimeout(()=>{
    $('.loader-container').fadeOut(1000);    
    setTimeout(()=>{
      $('html').removeClass('overflow-hidden');
      $('body').addClass('animated');
    },500)
  }, 1000)

  
   // Get all "navbar-burger" elements
  var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach(function ($el) {
      $el.addEventListener('click', function () {

        // Get the target from the "data-target" attribute
        var target = $el.dataset.target;
        var $target = document.getElementById(target);

        // Toggle the class on both the "navbar-burger" and the "navbar-menu"
        $el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  };
},{passive: true});



