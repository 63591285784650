<template>
	<div>
		<div v-infinite-scroll="loadNextPage" infinite-scroll-disabled="busy" infinite-scroll-distance="8">
			<div class="columns is-centered is-multiline" v-lazy-container="{ selector: 'img' }">
				<div class="column is-3" v-for="branch in branches">
					<div class="team-card wow fadeInUp px-5 mt-6">
			          <h5 class="px-4 name mb-6">
			            <span class="is-block has-background-primary-light has-text-white has-text-centered has-text-weight-bold px-2 py-3 is-size-6">{{  branch.name }}</span>
			          </h5>
			          <p class="position has-text-centered mt-3">
			            <span class="has-text-primary">{{t('manager')}}: </span> {{  branch.manager }}
			          </p>
			          <p class="position has-text-centered mt-3">
			            <span class="has-text-primary">{{t('phone')}}: </span> {{  branch.phone }}
			          </p>
			          <hr class="line has-background-grey" />
			          <p class="position has-text-centered mt-3">
                          {{  branch.location }}
			          </p>
			          <ul class="social-media">
			            <li>
			              <a :href="branch.facebook" target="_blank" class="has-text-primary"><i class="fab fa-facebook-f"></i></a>
			            </li>
			            <li>
			              <a :href="branch.instagram" target="_blank" class="has-text-primary"><i class="fab fa-instagram"></i></a>
			            </li>
			            <li>
			              <a :href="branch.twitter" target="_blank" class="has-text-primary"><i class="fab fa-twitter"></i></a>
			            </li>
			          </ul>
			        </div>
			    </div>
			</div>

			<div class="has-text-centered full-width mt30" v-show="loadMore">
				<div class="iloader position-relative is-inline-block w-100">
					<img src="/front/img/illustrations/loader.svg" alt="" class="height-100" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import infiniteScroll from 'vue-infinite-scroll';
	export default {
		name: 'Team',
		directives: {
			infiniteScroll
		},
		props: {
			data: {
				type: Array,
				default: () => []
			},
			lang:{
				type: String,
				required: true
			},
			url: String,
		},
		computed: {
			busy() {
				return this.fetching
			}
		},
		data: () => ({
			branches: [],
			currentPage: 0,
			fetching: false,
			nextUrl : String,
			loadMore : true,
		}),
		methods: {
			loadNextPage() {
				this.currentPage++;
				this.fetching = true;
				if(this.nextUrl != null){
					axios.get(this.nextUrl).then(({ data }) => {
						this.branches.push(...data.data);
						this.nextUrl = data.links.next;
						this.fetching = false;
					});
				}
				else{
					this.loadMore = false;
				}
			}
		},
		created() {
			this.nextUrl = this.url
			this.branches = [];
			this.loadNextPage();
		},
		mounted() {
			this.$translate.setLang(this.lang);
		},
	};
</script>
